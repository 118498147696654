import {
    FETCH_MAIN__SUCCESS,
    FETCH_MAIN__LOADING,
    FETCH_MAIN__ERROR,
    FETCH_ROOMS__SUCCESS,
    FETCH_ROOMS__LOADING,
    FETCH_ROOMS__ERROR,
    FETCH_SERVICE__SUCCESS,
    FETCH_SERVICE__LOADING,
    FETCH_SERVICE__ERROR,
} from "./actionTypes";
import {fetchData} from "../../helpers/network";


// actions

// fetch main
export const actionFetchData = () => dispatch => {
    dispatch({type: FETCH_MAIN__LOADING});
    fetchData(`/api/v1/hotel/home/data`).then((data) => {

        dispatch({ type: FETCH_MAIN__SUCCESS, main:data});
    }).catch((error) => {
        dispatch({ type: FETCH_MAIN__ERROR, error});
    })
};


// fetch rooms
export const actionFetchRooms = (id) => dispatch => {
    dispatch({type: FETCH_ROOMS__LOADING});
    fetchData(`/api/v1/hotel/room/${id}/home/data`).then((data) => {
        dispatch({ type: FETCH_ROOMS__SUCCESS, rooms: data});
    }).catch((error) => {
        dispatch({ type: FETCH_ROOMS__ERROR, error});
    })
};

// fetch services
export const actionFetchService = (id) => dispatch => {
    dispatch({type: FETCH_SERVICE__LOADING});
    fetchData(`/api/v1/hotel/service/${id}/home/data`).then((data) => {
        dispatch({ type: FETCH_SERVICE__SUCCESS, service: data});
    }).catch((error) => {
        dispatch({ type: FETCH_SERVICE__ERROR, error});
    })
};