import React, {Component} from 'react';
import "./ToggleMenu.scss";

const ToggleMenu = (props) => {
  return (
      <div className={"menu__mobile-burger " + (props.toggle ? " boogrer-open" : "")} onClick={props.onClick}>
          <div className="menu___hr-top"></div>
          <div className="menu___hr-center"></div>
          <div className="menu___hr-bottom"></div>
      </div>
  )
};

export default ToggleMenu