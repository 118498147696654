import React from "react";
import "./Select.scss";
import {SelectArrow} from "../../Icons/Icons";

const Select = (props) => {
    return (
        <div className="select">
            <select {...props}>
                {props.children}
            </select>
            <SelectArrow className={"select__icon"}/>
        </div>
    )
};

export default Select;