import {combineReducers} from 'redux';
import main from "./main.js"
import rooms from "./rooms.js"
import service from "./service.js"


export default combineReducers({
    main,
    rooms,
    service,
})

