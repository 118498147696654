import React, {Component} from "react";
import "./New.scss";
import Aux from "../hoc/AuxC/AuxC";
import {Scrollbars} from 'react-custom-scrollbars';
import {IconClose} from "../UI/Icons/Icons";



class New extends Component {
    constructor(props) {
        super(props);
        let idsParse = [];
        let closeModal = false;

        const ids = localStorage.getItem('news_ids');
        idsParse = JSON.parse(ids);

        if (idsParse && idsParse.length) {
            idsParse.map((id) => {
                if (this.props.news.id == id) {
                    closeModal = true;
                }
            });
        }

        this.state = {
            closeNew: closeModal,
            hoverToModal: true,
        };

        if (idsParse && idsParse.length) {
           let exist = idsParse.some((id) => {
                return id == this.props.news.id
            });
           if(!exist) {
               idsParse.push(this.props.news.id);
           }
            localStorage.setItem('news_ids', JSON.stringify(idsParse))
        } else {
            localStorage.setItem('news_ids', JSON.stringify([this.props.news.id]))
        }
    }


    componentDidMount() {
        const self = this;
        // const toggle = document.getElementById('toggle');
        const modal = document.getElementById('new-modal');
        self.setState({hoverToModal: false});

        modal.addEventListener('mouseover', () => {
            self.setState({hoverToModal: true})
        }, false);

        modal.addEventListener('mouseout', () => {
            self.setState({hoverToModal: false})
        }, false);
    }

    closeNew = () => {
        this.setState({closeNew: true})
    };

    openNew = () => {
        this.setState({closeNew: false})
    };

    render() {
        const {news = {}} = this.props;
        return (
            <Aux>
                <div className={`new__back ${this.state.closeNew ? 'hide' : 'show'}`} onClick={this.closeNew}></div>
                <div className={`new ${this.state.closeNew ? 'hide' : 'show'}`} id="new-modal">
                    <div className="close__new" onClick={this.closeNew}>
                        <IconClose/>
                    </div>
                    <div className="new__wrapper">

                        {news.img ? <div className="new__left">
                            <div className="new__image" style={{backgroundImage: `url('${news.img}')`}}></div>
                        </div> : null}

                        <div className="new__right">
                            <Scrollbars autoHide={false} autoHeightMax="auto" autoHeight={false} renderThumbVertical={props => <div {...props} className="track-vertical"/>}>
                                <div className="new__inner">
                                    <h1>{news.title}</h1>
                                    <div dangerouslySetInnerHTML={{__html: news.descr}}></div>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </div>

                <div className={`new__toggle ${this.state.closeNew ? 'show' : 'hide'}`} id="toggle"
                     onClick={this.openNew}>
                    <span className="toggle__text">
                        {news.title}
                    </span>
                </div>
            </Aux>
        )
    }
}


export default New;
