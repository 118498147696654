import React from "react";
import {Link, withRouter} from "react-router-dom";

// Підставляє тільки префікс мови якщо він існує
class LinkPrefix extends React.Component {
    render() {
        const { to, staticContext, ...rest } = this.props;

        const {params} = this.props.match;
        const matchLang = params.lng ? `/${params.lng}` : "";

        return (
            <Link {...rest} to={typeof to === 'string' ? matchLang + to : to} >{this.props.children}</Link>
        )
    }
};

export default withRouter(LinkPrefix);