import React from "react";


const IconProps = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0711 10.1421L10 17.2132L2.92894 10.1421C0.976322 8.18951 0.976322 5.02369 2.92894 3.07106C4.88157 1.11844 8.04739 1.11844 10 3.07106C11.9526 1.11844 15.1185 1.11844 17.0711 3.07106C19.0237 5.02369 19.0237 8.18951 17.0711 10.1421Z" stroke="#C1C7D0" strokeWidth="2" strokeMiterlimit="16" strokeLinecap="round"/>
    </svg>
  )
}

export default IconProps;