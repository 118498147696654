import React, {Component} from "react";
import Modal from "../../components/UI/Modal/Modal.jsx";
import "./ModalServices.scss";
import DoubleCarouselsLayout from "../DoubleCarouselsLayout/DoubleCarouselsLayout.jsx";
import {actionFetchService} from "../../store/actions/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Aux from "../../components/hoc/AuxC/AuxC";
import {PreloaderList} from "../../components/UI/Preloader/Preloader.jsx";
import MediaQuery from 'react-responsive';
import Slider from "react-slick";
import ModalMobile from "../../components/UI/MobileModal/MobileModal.jsx";
import {Scrollbars} from 'react-custom-scrollbars';
import {withI18n} from "react-i18next";

class ModalServices extends Component {
  goBack = (e) => {
    e.stopPropagation();
    this.props.history.push('/')
    // this.props.history.goBack();
  };

  fetchService = () => {
    const {serviceId} = this.props.match.params;
    this.props.fetchService(serviceId)
  };


  componentWillMount() {
    this.fetchService()
  }

  render() {
    const {isFetching, t} = this.props;
    const {photos = [], title = "", descr = "", file = ""} = this.props.service;

    const settingsMobile = {
      slidesToShow: 1.2,
      slidesToScroll: 1,
      arrows: false
    };
    // photos, title, descr
    return (
      <Aux>
        {/* FOR MOBILE */}
        <MediaQuery maxWidth={900}>
          {!isFetching ?
            <ModalMobile closePath="/"
                         onClickClose={this.goBack}
                         className={"modal-service-mobile"}
                         name={title}>

              <Slider {...settingsMobile}>
                {
                  photos.map((photo, index) => {
                    return (
                      <div key={index}>
                        <div className="modal-service-mobile__image"
                             style={{backgroundImage: `url('${photo.img}')`}}></div>
                      </div>
                    )
                  })
                }
              </Slider>

              <div className="modal-service-mobile__descr"
                   dangerouslySetInnerHTML={{__html: descr}}></div>

              {file ?
                <div className="modal-service-mobile__pdf">Переглянути <a href={file} target="_blank">
                  Меню (pdf)</a></div> : null}
            </ModalMobile> : <PreloaderList/>}
        </MediaQuery>


        <Modal closePath="/" onClickClose={this.goBack} className={"modal-services"}>

          {!isFetching ? <Aux>
              <div className="modal-services__wrapper">
                <DoubleCarouselsLayout photos={photos}/>

                <div className="modal-services__data">

                  <Scrollbars autoHeightMax="auto" height="100%">
                    <div className="modal-services__text">
                      <h1>{title}</h1>
                      <div className="modal-services__description"
                           dangerouslySetInnerHTML={{__html: descr}}></div>
                      {file ?
                        <div className="modal-service__pdf">{t('Переглянути')} <a href={file} target="_blank">{t('Меню')}
                          (pdf)</a></div> : null}
                    </div>
                  </Scrollbars>

                </div>
              </div>
            </Aux>
            :
            <PreloaderList/>
          }
        </Modal>
      </Aux>

    )
  }
}

const mapStateToProps = (state) => {
  const {data = {}, isFetching} = state.service;

  return {
    service: data,
    isFetching
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchService: (serviceId) => {
      dispatch(actionFetchService(serviceId))
    }
  }
};


export default withI18n()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalServices)));
