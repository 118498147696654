import React from "react";
import ContentLoader from 'react-content-loader';

export const PreloaderList = (props) => {

    return (
        <div className="preloader-list" {...props} style={{backgroundColor:'white', padding:20}}>
            <ContentLoader
                speed={2}
                width={1200}
                height={300}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                {...props}
            >
                <circle cx="10" cy="20" r="8"/>
                <rect x="25" y="15" rx="5" ry="5" width="220" height="10"/>
                <circle cx="10" cy="50" r="8"/>
                <rect x="25" y="45" rx="5" ry="5" width="180" height="10"/>
                <circle cx="10" cy="80" r="8"/>
                <rect x="25" y="75" rx="5" ry="5" width="220" height="10"/>
                <circle cx="10" cy="110" r="8"/>
                <rect x="25" y="105" rx="5" ry="5" width="160" height="10"/>
            </ContentLoader>
        </div>

    )
};