import React, {Component} from "react";
import "./Prices.scss";
import {Scrollbars} from 'react-custom-scrollbars';
import {Button} from "../UI/Forms/Buttons/Buttons";
import {Link} from 'react-scroll';
import Aux from "../hoc/AuxC/AuxC";
import Container from "../UI/Container/Container";
import {withI18n} from "react-i18next";
import TitleWithDescription from "../UI/TitleWithDescription/TitleWithDescription";

class Prices extends Component {
    tableTdParse = (td) => {
        if ((td != null) && (td.price_weekdays || td.price_weekend)) {

            const formatter = new Intl.NumberFormat('uk');

            return (
                <Aux>
                    <div className={`price_weekdays ${!td.price_weekend ? 'price__alldays' : ""}`}>{formatter.format(td.price_weekdays)}</div>
                    <div className="price_weekend">{td.price_weekend ? formatter.format(td.price_weekend) : ""}</div>
                </Aux>
            )
        } else if (typeof td === "string") {
            return td;
        } else {
            return ""
        }
    };

    splitDates = (date) => {
        const splitArray = date.split(" - ");
        return <div>{splitArray[0]}{splitArray[1] ? '-' : ""}<div style={{marginRight:'4px'}}>{splitArray[1]}</div></div>
    };


    render() {
        const {t,name, prices = [], price_title = {}} = this.props;

        const [th = [], ...rest] = prices;

        return (
            <div className="prices" name={name}>
                <TitleWithDescription
                  title={price_title.title}
                  description={price_title.descr}
                  center
                />

                <div className="price__wrapper">

                    <Scrollbars autoHeightMax="auto" style={{width: '100%', height: '100%'}} autoHeight={true}>
                        <table className="price__table" border="0" cellSpacing="0">
                            <thead>
                            <tr>
                                {
                                    th.map((item, index) => {
                                        return (
                                            <th key={index}>{this.splitDates(item[0])}</th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                rest.map((tr = [], index) => {

                                    return (
                                        <tr key={index}>
                                            {
                                                tr.map((td, indexTd) => {
                                                    //price_weekdays //price_weekend
                                                    return (
                                                        <td key={indexTd}>{this.tableTdParse(td)}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }

                            </tbody>
                        </table>

                    </Scrollbars>
                        <div className="price__nav">
                            <div className="price__legend">
                                <div>{t('Понеділок, вівторок середа, четвер, неділя')}</div>
                                <div className="price__color--red">{t('П’ятниця, субота')}</div>
                                <div className="price__color--yellow">{t('Всі дні тижня')}</div>
                            </div>
                            <div className="price__button">
                                <Link to="reservation" spy={true} offset={-90} smooth={true} duration={500}
                                      className="menu__button">
                                    <Button>{t('Бронювати')}</Button>
                                </Link>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}


export default withI18n()(Prices);