import React from "react";


const IconLocation = () => {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 20L14.5 14.5C17.5376 11.4624 17.5376 6.53757 14.5 3.5C11.4624 0.462435 6.53757 0.462435 3.5 3.5C0.462435 6.53757 0.462437 11.4624 3.5 14.5L9 20Z" stroke="#C1C7D0" strokeWidth="2" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconLocation;