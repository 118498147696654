import React from "react";

const IconSizes = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_40_3887)">
        <path d="M10 8.83984H12V4.83984H15L11 0.839844L7 4.83984H10V8.83984ZM22 11.8398L18 7.83984V10.8398H14V12.8398H18V15.8398L22 11.8398ZM12 14.8398H10V18.8398H7L11 22.8398L15 18.8398H12V14.8398ZM8 10.8398H4V7.83984L0 11.8398L4 15.8398V12.8398H8V10.8398Z" fill="#C1C7D0"/>
      </g>
      <defs>
        <clipPath id="clip0_40_3887">
          <rect width="22" height="22" fill="white" transform="translate(0 0.839844)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSizes