import {
    FETCH_MAIN__SUCCESS,
    FETCH_MAIN__LOADING,
    FETCH_MAIN__ERROR,
} from "../actions/actionTypes.js";


const initialState = {};

const main = (state = initialState, action) => {
    switch (action.type) {
        case  FETCH_MAIN__LOADING:
            return {...state, isFetching: true, data: {}};
        case FETCH_MAIN__SUCCESS:
            return {...state, isFetching: false, data: action.main};
        case FETCH_MAIN__ERROR:
            return {...state, error: action.error, isFetching: false};
        default:
            return state;
    }
};

export default main;