import React, {Component} from "react";
import "./DropDownLngMobile.scss";
import {withRouter} from "react-router-dom";
import i18n from "../../i18n";


class DropDownLngMobile extends Component {
    state ={
        lng: this.props.match.params.lng ? this.props.match.params.lng : 'uk'
    };

    selectLng = (lng) => {
        this.setState({lng: lng})
        i18n.changeLanguage(lng || 'uk');


        // push prefix
        const {pathname, search} = this.props.history.location;
        const patt = /\/uk|\/en/g;
        const result = patt.test(pathname);

        let nextPrefix = `/${lng}`;
        let nextPath;

        if (lng === 'uk') {
            if (result) {
                nextPath = pathname.slice(3) + search
            } else {
                nextPath = pathname + search
            }
        } else {
            if (result) {
                nextPath = nextPrefix + pathname.slice(3) + search;
            } else {
                nextPath = nextPrefix + pathname + search;
            }
        }

        this.props.history.replace(nextPath);

    };

    render() {
        return (

            <ul className="mobile__lng">
                <li className={this.state.lng === 'uk' ? `active` : ''} onClick={this.selectLng.bind(this,'uk')}>UK</li>
                <li className={this.state.lng === 'en' ? `active` : ''} onClick={this.selectLng.bind(this,'en')}>EN</li>
            </ul>

        )
    }
}

export default withRouter(DropDownLngMobile)