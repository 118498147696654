import 'babel-polyfill';
import React from 'react';

import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import "./index.scss";
import i18n from "./i18n.js";
import { Route,BrowserRouter as Router} from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./store/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import App from "./App";
import { I18nextProvider } from 'react-i18next';
// import {prefix} from "./config/app";
const prefix = '/:lng(uk|en|ru)?';
const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

// breadcrumbs https://stackoverflow.com/questions/35126428/dynamic-breadcrumbs-using-react-router
// datepicker https://www.npmjs.com/package/react-datepicker
// price range https://www.npmjs.com/package/react-input-range

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <Router>
                <Route path={`${prefix}/`} component={App} />
            </Router>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root')
);




serviceWorker.unregister();
