import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import LngDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationUK from './locales/uk/translation.json';
import translationRU from './locales/ru/translation.json';



// the translations
const resources = {
    uk: {
        translation: translationUK
    },
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    }
};


// options for lookup lang
// const options = {
//     order: ['path'],//'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'
//     lookupQuerystring: 'lng',
//     lookupCookie: 'i18next',
//     lookupLocalStorage: 'i18nextLng',
//     lookupFromPathIndex: 0,
//     lookupFromSubdomainIndex: 0,
//     caches: ['localStorage', 'cookie'],
//     excludeCacheFor: ['cimode'],
//     cookieMinutes: 10,
//     cookieDomain: 'myDomain',
//     htmlTag: document.documentElement
// };
//
// const lngDetector = new LngDetector(null, options);

i18n.use(LngDetector)
    .use(reactI18nextModule)
    .init({

        resources,
        fallbackLng: "uk",
        // lng:"uk",
        // initImmediate: false,

        // keySeparator:false,
        interpolation:{
            escapeValue: false
        },
        react: {
            wait: true,
            omitBoundRerender: false
        }
    });


export default i18n;

