import React from "react";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MediaQuery from 'react-responsive';
import Aux from "../../../hoc/AuxC/AuxC";
import {Calendar} from "../../Icons/Icons";
import "./Datepicker.scss";


const Datepicker = (props) => {
    return (
        <Aux>
            <MediaQuery maxWidth={600}>
                <div className="datepicker">
                    <ReactDatePicker {...props} withPortal/>
                    <Calendar/>
                </div>
            </MediaQuery>
            {/* for mobile */}
            <MediaQuery minWidth={601}>
                <div className="datepicker">
                    <ReactDatePicker {...props}/>
                    <Calendar/>
                </div>
            </MediaQuery>
        </Aux>
    )
};

export default Datepicker