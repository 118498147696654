import React, {Component} from "react";
import "./DropDownLng.scss";
import {withRouter} from "react-router-dom";
// import i18n from '../../i18n';
// import {IconLocation} from "../../Icons/HeaderIcons/IconHeader";
// import { Trans } from 'react-i18next';
import {withI18n, Trans} from "react-i18next";
import {SelectArrow} from "../UI/Icons/Icons";
import i18n from "../../i18n";

const body = document.getElementsByTagName('html')[0];

class DropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentItem: "",
            items: [],
            isOpen: false,
        }
    }

    closeMobileMenu() {
        this.setState({isOpen: false});
    }

    bodyClick = () => {
        if (this.state.isOpen) {
            this.setState({isOpen: false})
        }
    };

    // create listener
    componentDidMount() {
        const self = this;

        body.addEventListener('click', function () {
            self.bodyClick()
        });

        const languageItems = [{
            id: 1,
            lng: 'uk',
            oneItem: 'UK' //<Trans>Українська</Trans>
        }, {
            id: 2,
            lng: 'en',
            oneItem: 'EN' //<Trans>Англійська</Trans>,
        }];


        const {params} = this.props.match;
        const currantLng = params.lng ? params.lng : "";

        const currantItem = languageItems.filter(item => item.lng === currantLng)[0];
        const restArray = languageItems.filter(item => item.lng !== currantLng);


        this.setState({
            currentItem: currantItem ? currantItem : languageItems[0],
            items: currantItem ? restArray : languageItems.splice(1),
            isOpen: false
        });
    }

    // remove listener
    componentWillUnmount() {
        const self = this;

        body.removeEventListener('click', function () {
            self.bodyClick()
        })
    }

    handleSelect = (data) => {
        const {currentItem} = this.state;

        const nextItems = this.state.items.filter((item) => {
            return item.id !== data.id
        });

        this.setState({
            items: [currentItem, ...nextItems],
            currentItem: data,
            isOpen: false
        });

        if (this.props.selected) {
            this.props.selected(data);
        }

        i18n.changeLanguage(data.lng || 'uk');

        // push prefix
        const {pathname, search} = this.props.history.location;
        const patt = /\/uk|\/en/g;
        const result = patt.test(pathname);

        let nextPrefix = `/${data.lng}`;
        let nextPath;

        if (data.lng === 'uk') {
            if (result) {
                nextPath = pathname.slice(3) + search
            } else {
                nextPath = pathname + search
            }
        } else {
            if (result) {
                nextPath = nextPrefix + pathname.slice(3) + search;
            } else {
                nextPath = nextPrefix + pathname + search;
            }
        }

        this.props.history.replace(nextPath);
    };

    openDropDown = () => {
        this.setState((oldState) => {
            return {isOpen: !oldState.isOpen}
        })
    };

    render() {
        const {isOpen, currentItem, items} = this.state;

        return (
            <div className="drop-down">

                <div className="drop-down__active" onClick={this.openDropDown}>
                    <span className="drop-down__text">{currentItem.oneItem}</span>
                    <i className="drop-down__icon"><SelectArrow/></i>
                </div>

                <ul className={`drop-down__list ${!isOpen ? 'hide' : 'show'}`}>
                    {
                        items.map((item) => {
                            return (
                                <li className="drop-down__item" key={item.id} onClick={() => {
                                    this.handleSelect(item);
                                    this.closeMobileMenu();
                                }}>
                                    <span className="drop-down__text">{item.oneItem}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default withI18n()(withRouter(DropDown));