import {
    FETCH_ROOMS__SUCCESS,
    FETCH_ROOMS__LOADING,
    FETCH_ROOMS__ERROR,
} from "../actions/actionTypes.js";


const initialState = {};

const rooms = (state = initialState, action) => {
    switch (action.type) {
        case  FETCH_ROOMS__LOADING:
            return {...state, isFetching: true, data: {}};
        case FETCH_ROOMS__SUCCESS:
            return {...state, isFetching: false, data: action.rooms};
        case FETCH_ROOMS__ERROR:
            return {...state, error: action.error, isFetching: false};
        default:
            return state;
    }
};


export default rooms;