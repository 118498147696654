import React, {Component} from "react";
import Slider from "react-slick";
import "./DoubleCarouselsLayout.scss";

class DubleCarouselsLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {

        const multi = {
            dots: false,
            arrows: false,
            infinite: true,
            // vertical: true,
            // verticalSwiping: true,
            slidesToShow: 4.2,
            slidesToScroll: 1,
            speed: 500,
            focusOnSelect: true,
        };


        const single = {
            adaptiveHeight: true,
            dots: false,

            draggable:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: false,
            focus: false,
            arrows: true,
        };


        const {photos} = this.props;
        return (
            <div className="double-carousel__carousel">
                <div className="double-carousel__single">
                    <Slider
                        {...single}
                        // arrows={true}
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                    >
                        {
                            photos.map((photo, index) => {
                                return (
                                    <div key={index}>
                                        <div className="single__image"
                                             style={{backgroundImage: `url('${photo.img}')`}}></div>
                                    </div>
                                )
                            })
                        }

                    </Slider>
                </div>
                <div className="double-carousel__multiple">
                    <Slider
                        {...multi}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        // slidesToShow={4.2}
                        // slideToScroll={4}
                        // arrows={false}
                        // swipeToSlide={true}
                        // focusOnSelect={true}
                    >
                        {
                            photos.map((photo, index) => {
                                return (
                                    <div key={index}>
                                        <div className="multiple__image"
                                             style={{backgroundImage: `url('${photo.img}')`}}></div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        )
    }
}


export default DubleCarouselsLayout;