import React, {Component} from "react";
import "./MobileModal.scss";
import ReactDOM from "react-dom";
import {IconClose} from "../Icons/Icons";
import {Button} from "../Forms/Buttons/Buttons";
import {Link} from 'react-scroll';
import {withI18n} from "react-i18next";
const body = document.getElementsByTagName('body')[0];
const root = document.getElementById('root');

// поки що скрол не враховую // використовую хак для html
class ModalMobile extends Component {

    componentDidMount() {
        body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        body.style.overflow = 'visible';
    }

    render() {
        const {className, children, style, name = "", t} = this.props;
        return (
            ReactDOM.createPortal(
                <div className="modal-mobile">
                    <div className="modal-mobile__header">
                        <h2>{name}</h2>
                        <div className="modal-mobile__button-close" onClick={this.props.onClickClose}>
                            <IconClose/>
                        </div>
                    </div>
                    <div className={`modal-mobile-content ${className ? className : ""}`} style={style}>
                        {children}
                    </div>

                    <div onClick={this.props.onClickClose}>
                        <div className="modal-mobile-close"></div>
                    </div>

                    {this.props.showButton ? <div className="modal-mobile__footer">
                        <Link to="reservation" onClick={this.props.onClickClose} spy={true} offset={-50} smooth={true} duration={500}>
                            <Button>{t('Бронювати')}</Button>
                        </Link>

                    </div>: null}
                </div>,
                document.getElementById('modal')
            )

        )
    }
}

export default withI18n()(ModalMobile);