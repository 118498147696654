import React, {Component} from "react";
import "./Modal.scss";
// import {IconClose} from "../Icons/SignUp/IconSignUp";
import ReactDOM from "react-dom";
import {IconClose} from "../Icons/Icons";

const body = document.getElementsByTagName('body')[0];
const root = document.getElementById('root');

// поки що скрол не враховую // використовую хак для html
class Modal extends Component {

    componentDidMount() {
        body.style.overflow = 'hidden';
        // root.style.filter = 'blur(2px)';
        // body.style.paddingRight = '15px';
    }

    componentWillUnmount() {
        body.style.overflow = 'visible';
        // root.style.filter = 'blur(0)';
        // body.style.paddingRight = '0';
    }

    render() {
        const {className, children, style} = this.props;
        return (
            ReactDOM.createPortal(
                <div className="modal">
                    <div className={`modal-content ${className ? className : ""}`} style={style}>
                        <div className="modal__button-close" onClick={this.props.onClickClose}>
                            <IconClose/>
                        </div>
                        {children}
                    </div>
                    <div onClick={this.props.onClickClose}>
                        <div className="modal-close"></div>
                    </div>
                </div>,
                document.getElementById('modal')
            )

        )
    }
}

export default Modal;