import {
    FETCH_SERVICE__SUCCESS,
    FETCH_SERVICE__LOADING,
    FETCH_SERVICE__ERROR,
} from "../actions/actionTypes.js";


const initialState = {};

const service = (state = initialState, action) => {
    switch (action.type) {
        case  FETCH_SERVICE__LOADING:
            return {...state, isFetching: true, data: {}};
        case FETCH_SERVICE__SUCCESS:
            return {...state, isFetching: false, data: action.service};
        case FETCH_SERVICE__ERROR:
            return {...state, error: action.error, isFetching: false};
        default:
            return state;
    }
};


export default service;