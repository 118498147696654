import React from 'react';
import "./TitleWithDescription.scss";


const TitleWithDescription = ({description = '', title ='', center = false}) => {
  return (
    <div className={`title-with-description ${center ? 'center' : ''}`}>
      {title && <h1>{title}</h1>}
      {description && <div className="title-with-description__description" dangerouslySetInnerHTML={{__html: description}}></div>}
    </div>
  );
};

export default TitleWithDescription;
