export const FETCH_MAIN__SUCCESS = "FETCH_MAIN__SUCCESS";
export const FETCH_MAIN__LOADING = "FETCH_MAIN__LOADING";
export const FETCH_MAIN__ERROR = "FETCH_MAIN__ERROR";

export const FETCH_ROOMS__SUCCESS = "FETCH_ROOMS__SUCCESS";
export const FETCH_ROOMS__LOADING = "FETCH_ROOMS__LOADING";
export const FETCH_ROOMS__ERROR = "FETCH_ROOMS__ERROR";

export const FETCH_SERVICE__SUCCESS = "FETCH_SERVICE__SUCCESS";
export const FETCH_SERVICE__LOADING = "FETCH_SERVICE__LOADING";
export const FETCH_SERVICE__ERROR = "FETCH_SERVICE__ERROR";