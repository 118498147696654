import React, {Component} from 'react';
import './App.scss';
import {withI18n} from "react-i18next";
import Body from "./layout/Body/Body";
import Footer from "./layout/Footer/Footer.jsx";
import {Route, withRouter} from "react-router-dom";
import ModalRoom from "./modal/ModalRoom/ModalRoom.jsx";
import ModalServices from "./modal/ModalServices/ModalServices";
import {initLng} from "./helpers/network";
import Aux from "./components/hoc/AuxC/AuxC";
import i18n from "./i18n";

const prefix = '/:lng(uk|en|ru)?';


class App extends Component {

  componentWillMount() {
    const {params = {}} = this.props.match;
    i18n.changeLanguage(params.lng || 'uk');
    initLng(params.lng || 'uk');
  }


  render() {
    return (
      <Aux>
        <Route path={`${prefix}/`} component={Body}/>
        <Route path={`${prefix}/rooms/:roomId`} component={ModalRoom}/>
        <Route path={`${prefix}/services/:serviceId`} component={ModalServices}/>
        <div name="contacts">
          <Footer/>
        </div>

      </Aux>
    );
  }
}

export default withRouter(withI18n()(App));
